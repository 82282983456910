/* menu-item.css */
.menu-item {
    opacity: 0;
    /* transform: translateY(20px); */
    animation: fadeInUp 0.5s ease forwards;
    transition: transform 0.3s ease, opacity 0.3s ease;
    list-style: none;
  }

  .menu-item a {
    margin: 5px;
  }
  
  .menu-item-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .menu-bullet-logo {
    transition: transform 0.3s ease;
    /* margin-right: 10px; */
  }
  
  .menu-link {
    text-decoration: none;
    /* color: var(--maintext-color); */
  }

/* Disable hover styles */
.menu-item:not(.menu-item-hover) .menu-item-content {
  transform: none; /* Prevent scaling */
}

.menu-item:not(.menu-item-hover):hover .menu-item-content,
.menu-item:not(.menu-item-hover):hover .menu-bullet-logo {
  transform: none; /* Disable hover animations */
}
