/* header.css */
@media (min-width: 768px) {
  :root {
    --header-height: 11vh;
  }  
}

@media (max-width: 768px) {
  :root {
    --header-height: 7vh;
  }

  .header {
    padding: 0 10px;
  }
}

.header {
    position: sticky;
    top: 0;
    z-index: 2000;
  }

  .header .logo {
    transform: rotate(45deg);
  }
  
  .header-container {
    height: var(--header-height);
    margin: auto;
  }

 