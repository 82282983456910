/* footer.css */
.footer {
  /* position: relative; Default positioning */
  z-index: 9000; /* Ensure it's above content */
  padding: 15px;
  width: 100%;
}

.sticky-footer {
  position: fixed; /* Make it sticky */
  bottom: 0; /* Stick to the bottom of the viewport */
  left: 0; /* Align to the left */
}

.relative-footer {
  position: relative; /* Normal document flow */
}

.footer-menu {
  list-style: none;
  gap: 20px;
}

.footer-menu-list {
  margin: 0;
  padding: 0;
}

.footer-menu a {
  color: var(--maintext-color);
  text-decoration: none;
  transition: var(--transition);
}

.social-icons {
  list-style: none;
}

@media (max-width: 768px) {
.social-icons {
  padding-top: 20px;
}
}