/* items-template.css */

/* Base container is "flex wrap gap-4", 
   so we rely on flex-basis for columns. */
   .colmax1 {
    flex: 1 1 calc(100% - 20px); 
    /* or you can remove the -20px if you prefer */
  }
  
  .colmax2 {
    flex: 1 1 calc(50% - 20px);
  }
  
  .colmax3 {
    flex: 1 1 calc(33.3333% - 20px);
  }
  
  .colmax4 {
    flex: 1 1 calc(25% - 20px);
  }

  .colmax1,
  .colmax2,
  .colmax3,
  .colmax4 {
    width: 100%;
  }
  
  /* 
     Then override for small screens to 
     collapse everything to 1 column if you like:
  */
  @media (max-width: 768px) {
    .colmax1,
    .colmax2,
    .colmax3,
    .colmax4 {
      flex: 1 1 calc(100% - 20px);
    }
  }
  