.floating-phone-button {
    position: fixed;
    bottom: 60px;          
    right: 30px;           
    z-index: 999; 
    transition: opacity 1.5s ease; 
  }
  
  /* By default, the icon size is set via CSS. 
     (Your Icon component should render a child with class "icon" for these rules to apply.) */
  .floating-phone-button .icon {
    font-size: .7em; 
  }
  
  @media (min-width: 1024px) {
    .floating-phone-button .icon {
      font-size: 2.2em;
      /* right: 30px;            */
    }
  }
  